<template>
  <div class="customer-detail">
    <p class="detail-info">
      <Icon :path="mdiAccount" class="material-icons" />
      {{ customerDetail?.name || "Guest" }}
    </p>
    <p class="detail-info">
      <Icon :path="mdiPhone" :size="16" class="material-icons" />
      <a :href="'tel:' + customerDetail?.phone">
        {{ customerDetail?.phone || "-" }}
      </a>
    </p>
  </div>
</template>

<script>
  import { mdiAccount, mdiPhone } from "@mdi/js";

  export default {
    data() {
      return {
        mdiAccount,
        mdiPhone,
      };
    },
    props: {
      customerDetail: {
        type: Object,
      },
    },
  };
</script>

<style lang="scss">
  .customer-detail {
    color: #585858;
    border: 1px solid #eee;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    gap: 0.25rem;
    width: 100%;
  }
</style>
