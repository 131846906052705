export const orderStatuses = {
  CREATED: "Created",
  PLACED: "Placed",
  PRODUCING: "Producing",
  READY_FOR_COLLECTION: "ReadyForCollection",
  DRIVER_COLLECTED: "DriverCollected",
  COMPLETED: "Completed",
  IN_TRANSIT: "InTransit",
  REFUNDED: "Refunded",
  CANCELLED: "Cancelled",
  REJECTED: "Rejected",
  DELIVERY_UNSUCCESSFUL: "DeliveryUnsuccessful",
};
