<template>
  <div class="expand-toggles" @click="this.$emit('click')">
    <icon
      class="material-icons"
      :path="mdiChevronUp"
      :size="18"
      v-if="onState"></icon>
    <icon
      class="material-icons"
      :path="mdiChevronDown"
      :size="18"
      v-else></icon>
  </div>
</template>
<script>
  import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
  export default {
    data() {
      return {
        mdiChevronDown,
        mdiChevronUp,
      };
    },
    props: {
      onState: {
        type: Boolean,
      },
    },
    emits: ["click"],
  };
</script>

<style lang="scss">
  .expand-toggles {
    display: flex;
    align-items: center;
    margin-left: 0.5rem !important;
    position: relative;
    top: 2px;
    span.material-icons {
      color: #aaa !important;
      font-size: 1rem !important;
    }
  }
</style>
