<template>
  <div>
    <div class="menubar">
      <router-link class="logo-link" :to="{ path: '/' }">
        <img
          class="logo"
          alt="Tuck Trucks Logo"
          :src="
            this.getCdnFile('/images/logos/tuck-trucks/tt-logo-black.png')
          " />
      </router-link>

      <div class="controls-container">
        <span @click="showModal = true"
          ><Menu
            :size="28"
            fillColor="black"
            title="Menu"
            class="menu"
            :class="{ 'mobile-layout': mobileLayout }"
        /></span>
      </div>
    </div>

    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          v-if="showModal"
          class="modal"
          :maxWidth="'450'"
          @close="showModal = false">
          <ul class="app-menu">
            <li class="app-menu_item" @click="logout">
              <div class="logout-message">
                <Icon class="user-icon" :path="mdiAccountCircle" />Sign out of
                this account
              </div>
            </li>
          </ul>
        </ModalDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import store from "../store";
  import router from "../router";
  import ModalDialog from "@/components/ModalDialog";
  import { mdiAccountCircle } from "@mdi/js";

  export default {
    data() {
      return {
        showModal: false,
        mdiAccountCircle,
      };
    },
    methods: {
      logout() {
        store.dispatch("apiPrivate/logout");
        router.push("/login");
      },
    },
    emits: ["swapView", "expandTabs", "selectTab", "swapToTabView"],
    props: {
      mobileLayout: {
        type: Boolean,
      },
    },
    components: {
      ModalDialog,
    },
  };
</script>

<style lang="scss">
  .menubar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
  }

  .theme-light {
    .menubar {
      color: $col_softblack;
      background: $col_offwhite;
      box-shadow: 0 2px 5px #ddd;
    }
  }

  .logo-link {
    margin-top: 7px;
  }

  img.logo {
    height: 16px;
    padding: 0;
    margin: 0;
  }

  .controls-container {
    display: flex;
    align-items: center;
  }

  .menu {
    display: block;
    cursor: pointer;
  }

  .swap-view {
    display: none;
    cursor: pointer;
    margin-right: 1rem;

    .mobile-layout & {
      display: block;
    }
  }

  .new-order-btn {
    padding: 0.25rem 0.5rem;
    margin-right: 1rem;
  }

  .app-menu {
    &_item {
      cursor: pointer;
    }
  }

  .logout-message {
    display: flex;
    align-items: center;
    padding: 1rem;
    text-decoration: underline;

    .user-icon {
      margin: 0.25rem 0.5rem 0 0;
      color: $col_beta-darker;
    }
  }
</style>
