<template>
  <div class="vendor-detail">
    <p class="detail-info">
      <Icon :path="mdiPhone" :size="16" class="material-icons" />
      <a :href="'tel:' + vendorContactDetail?.phoneOffice">
        {{ vendorContactDetail?.phoneOffice || "-" }}
      </a>
    </p>
    <p class="detail-info">
      <Icon :path="mdiMapMarker" :size="16" class="material-icons" />
      {{ location || vendorName }}
    </p>
  </div>
</template>
<script>
  import { mdiPhone, mdiEmail, mdiMapMarker } from "@mdi/js";

  export default {
    data() {
      return {
        mdiPhone,
        mdiEmail,
        mdiMapMarker,
      };
    },
    props: {
      vendorContactDetail: {
        type: Object,
      },
      vendorName: {
        type: String,
      },
      location: {
        type: String,
      },
    },
  };
</script>

<style lang="scss">
  .vendor-detail {
    color: #585858;
    border: 1px solid #eee;
    padding: 0.25rem !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.5rem !important;
    gap: 0.25rem;
    width: 100%;
  }
  .detail-info {
    font-size: 0.8rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
    .material-icons {
      font-size: 0.8rem;
    }
  }
</style>
