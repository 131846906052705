<template>
  <div
    class="map fixed"
    ref="map"
    :style="{ height: mapSize.height, width: mapSize.width }">
    <div class="map-holder">
      <div id="map"></div>
      <!-- <div id="instructions"></div> -->
    </div>
  </div>
</template>

<script>
  const mapboxgl = window.mapboxgl;
  import { mapGetters } from "vuex";
  import { parseLatLng } from "@tucktrucks/core";
  import store from "@/store";

  export default {
    data() {
      return {
        loading: false,
        location: "",
        access_token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
        map: null,
        profile: "cycling",
        start: this.origin,
        steps: true,
        venueMarker: null,
        destinationMarkers: {},
        mapSize: { height: null, width: null },
      };
    },

    props: {
      fullMode: {
        type: Boolean,
      },
      origin: {
        type: String,
        default: "-1.30792 51.58269",
      },
    },

    computed: {
      ...mapGetters({
        coordinatesVenue: "coordinates/venue",
        coordinatesDestinations: "coordinates/destinations",
        coordinatesCurrentDestination: "coordinates/currentDestinationId",
        currentRoute: "routes/current",
      }),
    },

    watch: {
      currentRoute: function () {
        if (this.map != null) {
          if (this.currentRoute.route?.length > 0) {
            this.createPath();
            this.resetMarkers();
          } else {
            this.removePath();
            this.setMarkers();
          }
        }
      },

      coordinatesVenue: function () {
        if (this.map != null) {
          let venuePoint = parseLatLng(this.coordinatesVenue);
          this.venueMarker ??= new mapboxgl.Marker({
            color: "#faa9a9",
          });
          this.venueMarker.setLngLat(venuePoint);
          this.venueMarker.addTo(this.map);
          this.map.flyTo({ center: venuePoint, zoom: 15 });
        }
      },

      coordinatesDestinations: function () {
        this.setMarkers();
      },

      fullMode: function () {
        if (this.fullMode) this.mapSize = { height: "100%", width: "100%" };
        if (!this.fullMode) this.mapSize = { height: null, width: null };

        setTimeout(() => {
          this.map.resize();
        }, 10);
      },
    },

    methods: {
      createMap() {
        try {
          mapboxgl.accessToken = this.access_token;

          let start = parseLatLng(this.start);
          let map = new mapboxgl.Map({
            container: "map",
            center: start,
            style: "mapbox://styles/chris-tt/cl1dyz5ft005a14r0y122wvof",
            zoom: 15,
          });

          // map.addControl(new mapboxgl.FullscreenControl());

          this.map = map;
        } catch (err) {
          window.log.error(err);
        }
      },

      setMarkers() {
        if (this.map != null) {
          this.coordinatesDestinations.forEach((destination) => {
            let marker = this.destinationMarkers[destination.id];

            marker ??= new mapboxgl.Marker({
              color:
                destination.id == this.coordinatesCurrentDestination
                  ? "#faa9a9"
                  : "#bbbbbb",
              scale: 0.75,
            });

            if (destination.coordinate) {
              marker.setLngLat(destination.coordinate);
              marker.addTo(this.map);

              this.destinationMarkers[destination.id] = marker;
            } else window.log.error("destination coordinate is null ");
          });
        }
      },
      createPath() {
        if (this.map.getSource("route")) {
          this.map.getSource("route").setData(this.currentRoute.geojson);
        } else {
          this.map.addSource("route", {
            type: "geojson",
            data: this.currentRoute.geojson,
          });

          this.map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#ff7575",
              "line-width": 8,
            },
          });
        }
      },
      removePath() {
        if (this.map.getSource("route")) {
          // remove line
          this.map.removeLayer("route");
          this.map.removeSource("route");

          // remove active marker
          let marker =
            this.destinationMarkers[this.coordinatesCurrentDestination];
          if (marker) marker.remove();

          // clear current destination
          store.dispatch("coordinates/setCurrentDestination", null);
        } else this.setMarkers();
      },
      resetMarkers() {
        this.destinationMarkers = {};
        this.setMarkers();
      },
    },

    mounted() {
      this.createMap();
    },
  };
</script>

<style scoped>
  @import "https://cdnjs.cloudflare.com/ajax/libs/mapbox-gl/2.10.0/mapbox-gl.min.css";

  #instructions {
    position: absolute;
    margin: 20px;
    width: 25%;
    top: 0;
    bottom: 20%;
    padding: 20px;
    background-color: #fff;
    overflow-y: scroll;
  }
</style>
