<template>
  <div @click="toggleTimingDetailHandler">
    <div class="row full-width time-label-wrap">
      <div class="row">
        <Icon class="material-icons" :path="mdiClockOutline" :size="14" />
        <div class="delivery-times">
          <div class="estimate-heading">{{ timeLabel }}</div>
          <div class="time-item">
            <div class="time">{{ timeValue }}</div>
          </div>
        </div>
      </div>
      <ToggleSets :onState="ifExpandTimingDetail" />
    </div>
  </div>
  <div v-if="ifExpandTimingDetail">
    <EstTimeSection :estimatedTimings="estimatedTiming" />
  </div>
</template>

<script>
  import { mdiClockOutline } from "@mdi/js";
  import ToggleSets from "@/components/ToggleSets.vue";
  import EstTimeSection from "@/components/delivery/EstTimeSection.vue";

  export default {
    name: "TimeLabel",
    data() {
      return {
        timeValue: null,
        timeLabel: null,
        ifExpandTimingDetail: false,
        mdiClockOutline,
      };
    },
    components: {
      ToggleSets,
      EstTimeSection,
    },
    props: {
      status: {
        type: String,
      },
      estimatedTiming: {
        type: Object,
      },
    },
    watch: {
      status: function () {
        this.getTimeValue();
      },
    },
    methods: {
      getTimeValue() {
        switch (this.status) {
          case "AwaitingCollection":
            this.timeValue = this.formatTime(
              this.estimatedTiming?.outboundDepartTime
            );
            this.timeLabel = "Depart to Collect Point at";
            break;
          case "DriverCollectingOrders":
          case "InTransit":
            this.timeValue = this.formatTime(
              this.estimatedTiming?.outboundArriveTime
            );
            this.timeLabel = "Arrive at Drop-Off Point by";
            break;
          case "AtDestination":
            this.timeValue = this.formatTime(
              this.estimatedTiming?.returnDepartTime
            );
            this.timeLabel = "Depart to Return Point at";
            break;
          case "Completed":
            this.timeValue = this.formatTime(
              this.estimatedTiming?.returnArriveTime
            );
            this.timeLabel = "Arrive at Return Point by";
            break;
          default:
            this.timeValue = this.formatTime(
              this.estimatedTiming?.outboundArriveTime
            );
            this.timeLabel = "Arrive at Drop-Off Point by";
            break;
        }
      },
      toggleTimingDetailHandler() {
        this.ifExpandTimingDetail = !this.ifExpandTimingDetail;
      },
    },
    mounted() {
      this.getTimeValue();
    },
  };
</script>

<style lang="scss">
  .full-width {
    width: 100%;
    &.row {
      justify-content: space-between;
    }
  }
  .time-label-wrap {
    gap: 0.2rem;
    align-items: center;
    .row {
      gap: 0.2rem;
    }
    span.material-icons {
      font-size: 0.75rem;
      color: $col_beta !important;
    }
    .expand-toggles span.material-icons {
      color: #aaa !important;
    }
  }
  .times-container {
    display: flex;
  }

  .time-item {
    display: flex;
    align-items: center;
  }

  .time {
    margin-right: 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .time-label {
    margin-right: 0.75rem;
    font-size: 0.6875rem;
    font-weight: 600;
    color: $col_faded-lighter;
  }
</style>
