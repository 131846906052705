<template>
  <p class="caption order-status row">
    <Icon
      :path="mdiCheck"
      :size="16"
      class="material-icons"
      v-if="status === 'DriverCollected' || status === 'Completed'" />
    {{ statusText }}
  </p>
</template>

<script>
  import { mdiCheck } from "@mdi/js";
  export default {
    data() {
      return {
        statusText: this.status,
        mdiCheck,
      };
    },
    props: {
      status: {
        type: String,
      },
    },
    watch: {
      status: function () {
        this.formatOrderStatus();
      },
    },
    methods: {
      formatOrderStatus() {
        switch (this.status) {
          case "ReadyForCollection":
            this.statusText = "Ready For Collection";
            break;
          case "DriverCollected":
            this.statusText = "Collected";
            break;
          case "Producing":
            this.statusText = "Producing...";
            break;
          default:
            this.statusText = this.status;
            break;
        }
      },
    },
    mounted() {
      this.formatOrderStatus();
    },
  };
</script>

<style lang="scss">
  .order-status {
    font-size: 0.75rem !important;
    color: $col_beta !important;
    line-height: 0.75rem !important;
    margin-bottom: 0.25rem;
    font-weight: bold;
    gap: 0.25rem;
    span.material-icons {
      font-size: 0.75rem;
    }
  }
</style>
