<template>
  <div class="address">
    <p class="highlight">{{ name }}</p>
    <p>{{ address }}</p>
  </div>
</template>

<script>
  export default {
    name: "Address",
    data() {
      return {
        address: this.addressProp,
        name: this.nameProp,
      };
    },
    props: {
      nameProp: {
        type: String,
        default: "",
      },
      addressProp: {
        type: String,
        default: "",
      },
    },
  };
</script>

<style lang="scss">
  .address {
    font-size: 0.6875rem;
    font-weight: 600;
    margin-bottom: 0.25rem !important;
    color: $col_softblack;
  }

  .highlight {
    font-size: 1.25rem;
    font-weight: 600;
    color: $col_black;
  }
</style>
