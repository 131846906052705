<template>
  <div class="column undelivery">
    <button
      class="text secondary small status"
      :disabled="disabled"
      @click="this.$emit('handleUndelivery')">
      Undeliver?
    </button>
    <p class="undelivery-tip">Available if no orders collected or handed off</p>
  </div>
</template>

<script>
  export default {
    data() {
      return { disabled: !this.canUndelivery };
    },
    props: {
      canUndelivery: {
        type: Boolean,
      },
    },
    watch: {
      canUndelivery: function () {
        this.disabled = !this.canUndelivery;
      },
    },
    emits: ["handleUndelivery"],
  };
</script>
<style lang="scss">
  .undelivery {
    align-items: center;
    button.text {
      padding: 0.5rem 0.25rem 0.25rem;
    }
    p.undelivery-tip {
      width: 100%;
      font-size: 0.7rem;
      text-align: center;
      line-height: 1em;
      color: #aaa;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column !important;
      p.undelivery-tip {
        text-align: center;
      }
      button.text {
        padding: 0.5rem 0.25rem 0.2rem;
      }
    }
  }
</style>
