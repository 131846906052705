<template>
  <div
    class="delivery-list_item card"
    :class="{
      current: this.apiPrivateDeliveriesGetCurrent?.id === delivery?.id,
    }"
    v-if="!loading">
    <div class="item column">
      <div class="overlay" v-if="removing">Removing...</div>
      <div class="details mb-2">
        <DropOffPoint
          :nameProp="delivery.destination?.name"
          :addressProp="delivery.destination?.googleAddress" />
        <TimeLabel
          :status="delivery.status"
          :estimatedTiming="delivery?.timings?.estimated" />
      </div>
      <div class="details delivery-list_vendor_list">
        <p class="caption mb-1" v-if="loadingOrders">Loading orders...</p>
        <ul v-if="vendors.length > 0 && !loadingOrders">
          <li v-for="vendor in vendors" :key="`vendor${vendor.id}`">
            <div
              class="row align-center full-width"
              @click="toggleVendorDetailHandler">
              <h3 class="name">
                {{ vendor.name }}
              </h3>
              <ToggleSets :onState="ifExpandVendorDetail" />
            </div>
            <VendorDetail
              v-if="ifExpandVendorDetail"
              :location="delivery.destination?.googleAddress"
              :vendorName="vendor.name"
              :vendorContactDetail="vendor?.mainContact" />
            <ul class="order-items-container">
              <li
                v-for="order in getOrdersByVendorId(vendor.id)"
                :key="`order${order.id}`"
                class="column order-item-container">
                <div class="row align-center space-between">
                  <div @click="toggleDetailHandler" class="row">
                    <div class="column">
                      <OrderStatus :status="order.status" />
                      <p class="caption order-id">No. {{ order.number }}</p>
                    </div>
                    <ToggleSets :onState="ifExpandDetail" />
                  </div>
                  <div class="row">
                    <button
                      v-if="
                        ![DRIVER_COLLECTED, COMPLETED].includes(order.status)
                      "
                      class="status"
                      :disabled="order.status !== READY_FOR_COLLECTION"
                      @click="updateOrderStatus(order)">
                      Collect Order
                    </button>

                    <button
                      v-if="order.status === DRIVER_COLLECTED"
                      class="status"
                      :disabled="delivery.status === DRIVER_COLLECTING_ORDERS"
                      @click="updateOrderStatus(order)">
                      Hand to {{ order?.customer?.name ?? "Guest" }}
                    </button>
                  </div>
                </div>
                <CustomerDetail
                  v-if="ifExpandDetail"
                  :customerDetail="order?.customer" />
                <ul
                  v-if="ifExpandDetail && order.items"
                  class="caption order-title">
                  Order items
                  <li
                    v-for="item in order.items"
                    :key="`item${item.id}`"
                    class="order-item column">
                    <div class="row">
                      <span class="count round center">{{ item?.amount }}</span>
                      <span class="item-name">{{ item?.name }} </span>
                    </div>
                    <div
                      class="alterations row"
                      v-if="item?.componentAlterations?.length > 0">
                      <p class="caption">Alterations</p>
                      <span
                        class="alteration"
                        v-for="alt in item?.componentAlterations"
                        :key="`alt${alt.id}`">
                        {{ alt?.name }}
                      </span>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <button
        v-if="updateDeliveryButtonIsVisisble"
        class="delivery-status row"
        @click="
          handleUpdateDeliveryStatus(
            delivery,
            isDeliveryInProgress ? true : false
          )
        ">
        <Icon
          :path="mdiCheck"
          :size="16"
          class="material-icons"
          v-if="isDeliveryInProgress" />
        {{ deliveryStatusButtonText }}
      </button>
      <UndeliveryBtnAndText
        @handleUndelivery="handleUndelivery"
        :canUndelivery="canUndelivery" />
    </div>
  </div>
</template>

<script>
  import DropOffPoint from "@/components/delivery/DropOffPoint";
  import TimeLabel from "@/components/delivery/TimeLabel";
  import { useToast } from "vue-toastification";
  import store from "@/store";
  import { mapGetters } from "vuex";
  import ToggleSets from "./ToggleSets.vue";
  import UndeliveryBtnAndText from "./delivery/UndeliveryBtnAndText.vue";
  import OrderStatus from "./delivery/OrderStatus.vue";
  import VendorDetail from "./delivery/VendorDetail.vue";
  import CustomerDetail from "./delivery/CustomerDetail.vue";
  import Enumerable from "linq";
  import { deliveryStatuses } from "../constants/deliveryStatuses.ts";
  import { orderStatuses } from "../constants/orderStatuses.ts";
  import { mdiCheck } from "@mdi/js";

  export default {
    components: {
      DropOffPoint,
      TimeLabel,
      ToggleSets,
      UndeliveryBtnAndText,
      OrderStatus,
      VendorDetail,
      CustomerDetail,
    },

    props: {
      deliveryProp: {
        type: Object,
        default: null,
      },
      incompleteDelivery: {
        type: Object,
      },
    },

    data() {
      return {
        loading: true,
        delivery: null,
        ifExpandDetail: false,
        ifExpandVendorDetail: false,
        isDeliveryAvailable: null,
        isLoadingBag: false,
        removing: false,
        canUpdateDeliveryStatus: false,
        loadingOrders: true,
        AWAITING_COLLECTION: deliveryStatuses.AWAITING_COLLECTION,
        DRIVER_COLLECTING_ORDERS: deliveryStatuses.DRIVER_COLLECTING_ORDERS,
        IN_TRANSIT: deliveryStatuses.IN_TRANSIT,
        COMPLETED: deliveryStatuses.COMPLETED,
        DRIVER_COLLECTED: orderStatuses.DRIVER_COLLECTED,
        AT_DESTINATION: deliveryStatuses.AT_DESTINATION,
        READY_FOR_COLLECTION: orderStatuses.READY_FOR_COLLECTION,
        DELIVERY_UNSUCCESSFUL: orderStatuses.DELIVERY_UNSUCCESSFUL,
        CANCELLED: orderStatuses.CANCELLED,
        REJECTED: orderStatuses.REJECTED,
        REFUNDED: orderStatuses.REFUNDED,
        PLACED: orderStatuses.PLACED,
        mdiCheck,
      };
    },

    watch: {
      async deliveryProp() {
        this.delivery = this.deliveryProp;

        this.checkIsDeliveryAvailable();
        this.checkIsLoadingBag();

        this.loading = false;
        this.loadingOrders = false;
        this.updateMap();
        this.checkIfCanUpdateDeliveryStatus();
      },

      hasNetworkError: function () {
        if (this.hasNetworkError) {
          this.loadingOrders = false;
        }
      },
    },

    computed: {
      ...mapGetters({
        apiPrivateDeliveriesGetById: "apiPrivateDeliveries/getById",
        apiPrivateDeliveriesGetCurrent: "apiPrivateDeliveries/getCurrent",
        hasNetworkError: "networkStatus/hasNetworkError",
        coordinatesCurrentDestination: "coordinates/currentDestinationId",
        currentRoute: "routes/current",
      }),

      deliveryStatusButtonText() {
        let buttonText;
        switch (this.deliveryProp.status) {
          case this.DRIVER_COLLECTING_ORDERS:
            buttonText = "Head to Drop-Off Point";
            break;
          case this.AT_DESTINATION:
          case this.IN_TRANSIT:
            buttonText = `I'm back at DiSH`;
            break;
          case this.COMPLETED:
            break;
          default:
            return "Head to collect point";
        }
        return buttonText;
      },

      isDeliveryInProgress() {
        return [this.IN_TRANSIT, this.AT_DESTINATION].includes(
          this.delivery?.status
        );
      },

      updateDeliveryButtonIsVisisble() {
        return ![this.COMPLETED, this.AWAITING_COLLECTION].includes(
          this.delivery?.status
        );
      },

      orders() {
        return this.delivery.orders;
      },

      vendors() {
        return Enumerable.from(this.orders)
          .select((x) => x.service.vendor)
          .distinct()
          .orderBy((x) => x.id)
          .toArray();
      },

      // checkIfCanUpdateDeliveryStatus() {
      //   const deliveryStatus = this.delivery.status;

      //   // const hasOrderNotCompleted =
      //   //   this.orders.filter(({ status }) => status !== this.COMPLETED).length > 0;

      //   const noOrderCollected =
      //     this.orders.filter(({ status }) => status === this.DRIVER_COLLECTED)
      //       .length < 1;

      //   return !(
      //     deliveryStatus === this.DRIVER_COLLECTING_ORDERS && noOrderCollected
      //   );
      // },

      canUndelivery() {
        const noOrdersCollectedOrCompleted = !this.orders.filter(
          ({ status }) =>
            status === this.DRIVER_COLLECTED || status === this.COMPLETED
        ).length;

        return (
          noOrdersCollectedOrCompleted &&
          this.delivery.status !== this.AWAITING_COLLECTION
        );
      },
    },

    methods: {
      checkIfCanUpdateDeliveryStatus() {
        const deliveryStatus = this.delivery?.status;

        if (deliveryStatus === "DriverCollectingOrders") {
          const allOrderCollected =
            this.orders.filter(({ status }) => status !== this.DRIVER_COLLECTED)
              .length === 0;

          return (this.canUpdateDeliveryStatus = allOrderCollected);
        }

        if (deliveryStatus === "AtDestination") {
          const allOrderCompleted =
            this.orders.filter(({ status }) => status !== this.COMPLETED)
              .length === 0;

          return (this.canUpdateDeliveryStatus = allOrderCompleted);
        }

        if (
          deliveryStatus === "DriverCollectingOrders" ||
          deliveryStatus === "AwaitingCollection"
        ) {
          return (this.canUpdateDeliveryStatus = true);
        }

        return (this.canUpdateDeliveryStatus = false);
      },
      toggleDetailHandler() {
        this.ifExpandDetail = !this.ifExpandDetail;
      },

      toggleVendorDetailHandler() {
        this.ifExpandVendorDetail = !this.ifExpandVendorDetail;
      },

      checkIsLoadingBag() {
        this.isLoadingBag =
          this.delivery?.status == this.AWAITING_COLLECTION ||
          this.delivery?.status == this.DRIVER_COLLECTING_ORDERS;
      },

      getOrdersByVendorId(id) {
        return this.orders.filter((x) => x.service.vendor.id == id);
      },

      handleGetNextDeliveryStatus(status, returnToVendor) {
        switch (status) {
          case this.AWAITING_COLLECTION:
            // this happens on returning more than one orders to vendors
            return returnToVendor
              ? this.AWAITING_COLLECTION
              : this.DRIVER_COLLECTING_ORDERS;

          case this.DRIVER_COLLECTING_ORDERS:
            return returnToVendor ? this.AWAITING_COLLECTION : this.IN_TRANSIT;

          case this.IN_TRANSIT:
          case this.AT_DESTINATION: // this should be removed later
            return this.COMPLETED;

          default:
            return status;
        }
      },

      handleGetNextOrderStatus(status, returnToVendor) {
        switch (status) {
          case this.READY_FOR_COLLECTION:
            return this.DRIVER_COLLECTED;

          case this.DRIVER_COLLECTED:
            return returnToVendor ? this.READY_FOR_COLLECTION : this.COMPLETED;

          default:
            return null;
        }
      },

      resetMap() {
        // const map = this.$parent.$refs.mapDirections;
        // map.reset();
      },

      updateMap() {
        if (
          this.delivery.status === this.IN_TRANSIT ||
          this.delivery.status === this.AT_DESTINATION
        ) {
          const destinationId = this.delivery.destination.id;
          store.dispatch("coordinates/setCurrentDestination", destinationId);

          if (this.coordinatesCurrentDestination !== this.currentRoute.id) {
            const start = store.state.coordinates.venue;
            const end = this.delivery.destination.coordinate;

            store.dispatch("routes/download", { start, end, destinationId });
          }
        }
      },

      async updateOrderStatus(order, returnToVendor = false) {
        if (
          this.incompleteDelivery &&
          this.incompleteDelivery.id !== this.delivery.id
        ) {
          return confirm(
            `This delivery is incomplete, are you sure you want to return to the venue?`
          );
        }

        const newOrderStatus = this.handleGetNextOrderStatus(
          order.status,
          returnToVendor
        );

        const response =
          await store.state.apiPrivate.client.endpoints.orders.changeStatus(
            newOrderStatus,
            order.id
          );
        if (response.status === 200) {
          const newStatus = response.data.data;
          const newDeliveryStatus = response.data.meta["delivery-status"];
          order.status = newStatus;

          store.dispatch("apiPublicOrders/upsert", order);

          if (newDeliveryStatus) {
            this.delivery.status = newDeliveryStatus;
            // store.dispatch("deliveries/updateDelivery", this.delivery);
            store.dispatch("apiPrivateDeliveries/upsert", this.delivery);

            this.updateMap();
            this.checkIsLoadingBag(newStatus);
          }

          this.checkIfCanUpdateDeliveryStatus();

          if (
            newStatus === this.DRIVER_COLLECTED ||
            newStatus === this.COMPLETED
          ) {
            return;
          }
        } else {
          useToast().error("Failed to update order status.");
        }
      },

      async handleUpdateDeliveryStatus(delivery, returnToVendor = false) {
        if (!this.canUpdateDeliveryStatus) {
          const check = confirm(
            "Some orders in this delivery are incompleted or waiting for collection, are you sure you want to update the delivery status?"
          );
          if (!check) {
            return;
          }
        }
        if (returnToVendor) {
          this.handleReturnToVendor(delivery);
          return;
        }
        this.updateDeliveryStatus(delivery, returnToVendor);
      },

      async updateDeliveryStatus(delivery, returnToVendor = false) {
        const prevStatus = delivery.status;
        const newStatus = this.handleGetNextDeliveryStatus(
          prevStatus,
          returnToVendor
        );

        store.dispatch("apiPrivateDeliveries/selectCurrent", delivery);

        const response =
          await store.state.apiPrivate.client.endpoints.deliveryStatus.update(
            newStatus,
            delivery.id
          );

        if (response.status === 200) {
          delivery.status = newStatus;
          store.dispatch("apiPrivateDeliveries/upsert", delivery);

          // if (prevStatus === this.IN_TRANSIT && newStatus === this.COMPLETED) {
          //   this.checkIsDeliveryAvailable();
          //   // setTimeout(() => {
          //   //   this.removing = true;
          //   //   this.$emit("removeCompletedDeliveryFromList");
          //   // }, 500);
          //   return;
          // }

          this.updateMap();
          this.checkIsLoadingBag(newStatus);
        } else {
          useToast().error("Failed to update delivery status.");
        }

        // TODO: Make sure there is a confirmation if there are any orders not handed off.
      },

      handleReturnToVendor(delivery) {
        const uncompletedOrders = this.getUncompletedOrders();

        if (uncompletedOrders > 0) {
          const uncompletedOrdersText = `You have ${uncompletedOrders} uncompleted ${
            uncompletedOrders === 1 ? "order" : "orders"
          }.`;

          if (
            !confirm(
              `${uncompletedOrdersText} Are you sure you want to return to the vendor?`
            )
          ) {
            return;
          }
        }

        this.updateDeliveryStatus(delivery, true);
        store.dispatch("routes/updateRoute", {});
      },

      getUncompletedOrders() {
        const completedOrderStatuses = [
          this.COMPLETED,
          this.CANCELLED,
          this.REFUNDED,
          this.REJECTED,
          this.DELIVERY_UNSUCCESSFUL,
          this.PLACED,
        ];

        return this.orders.filter(
          (order) => !completedOrderStatuses.includes(order.status)
        ).length;
      },

      checkIsDeliveryAvailable() {
        const currentDelivery = this.apiPrivateDeliveriesGetCurrent;
        const isDeliveryAvailable =
          !currentDelivery || currentDelivery.id === this.delivery.id;
        this.isDeliveryAvailable = isDeliveryAvailable;
      },

      async handleUndelivery() {
        const { id } = this.delivery;

        const response =
          await store.state.apiPrivate.client.endpoints.deliveryStatus.update(
            this.AWAITING_COLLECTION,
            id
          );

        if (response.status === 200) {
          store.dispatch("apiPrivateDeliveries/selectCurrent", null);

          // these functions don't exist
          // this.formatUpdateDeliveryStatusButtonText(this.AWAITING_COLLECTION);
          // this.updateIDBDeliveryStatus(this.delivery);
        } else {
          useToast().error("Failed to update delivery status.");
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .delivery-list_item {
    position: relative;
    overflow: hidden;
  }
  .current {
    box-shadow: $card_shadow_highlight;
  }

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 10px;
    padding: 3px 10px;
    min-height: 36px;
  }

  .delivery-status {
    width: 100%;
    gap: 0.5rem;
  }

  .customer-detail {
    color: #585858;
    border: 1px solid #eee;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    gap: 0.25rem;
    width: 100%;
  }

  .caption.status {
    border-radius: 0 !important;
    margin: 0;
    padding: 0 !important;
    background: none !important;
    color: $col_alpha !important;
    line-height: 0.9rem;
    text-align: left !important;
    height: initial !important;
  }
  .overlay {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffffa5;
    color: #888;
    font-style: italic;
  }

  .detail-info {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
    .material-icons {
      font-size: 0.8rem;
    }
  }

  .full-width {
    width: 100%;
    &.row {
      justify-content: space-between;
    }
  }

  div.alterations {
    gap: 0.25rem;

    p.caption {
      color: #333;
    }
    p.caption,
    span.alteration {
      font-size: 0.7rem;
    }

    span.alteration:not(:last-child)::after {
      content: ",";
    }
  }
  p.order-id,
  .order-title {
    font-weight: bold;
    color: #000;
  }

  .order-title {
    margin-top: 1rem;
  }
</style>
