<template>
  <div
    class="app-container"
    :class="{
      'mobile-layout': mobileLayout,
      'connection-lost': hasNetworkError,
    }">
    <TopBar class="topbar" :mobileLayout="mobileLayout" />
    <div class="main-content-container" id="main-content-container">
      <button
        class="icon map-size-toggle"
        aria-label="toggle-map-button"
        @click="toggleMapSize">
        <Icon :path="mdiArrowCollapseAll" :size="20" v-if="mapFullMode" />
        <Icon :path="mdiArrowExpandAll" :size="20" v-if="!mapFullMode" />
      </button>

      <!-- <MapDirections /> -->
      <MapBox ref="mapDirections" :fullMode="mapFullMode" />

      <transition name="slide-fade">
        <div v-if="ifShowList">
          <div class="delivery-list column fixed" v-if="!isDelivering">
            <div class="title">
              <h1 class="align-baseline">
                Delivery list ({{ this.formatDate(this.todaysEvent?.date) }})
                <span class="count">{{ deliveries?.length }}</span>
              </h1>
            </div>
            <section class="delivery-list_inner" v-if="!loadingDeliveries">
              <span v-for="delivery in deliveries" :key="delivery.id">
                <DeliveryListItem
                  v-if="containsValidOrders(delivery)"
                  :deliveryProp="delivery"
                  :uncompletedDelivery="uncompletedDelivery" />
              </span>
              <div
                class="card center"
                v-if="!deliveries || deliveries?.length < 1">
                No Deliveries
              </div>
            </section>
            <section v-else>Loading...</section>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import DeliveryListItem from "@/components/DeliveryListItem";
  import MapBox from "@/components/MapBox";
  import TopBar from "@/components/TopBar";
  import store from "@/store";
  import { mapGetters } from "vuex";
  import { deliveryStatuses } from "../constants/deliveryStatuses.ts";
  import { mdiArrowExpandAll, mdiArrowCollapseAll } from "@mdi/js";
  //import UnsavedChangesMixin from "@/mixins/UnsavedChangesMixin";

  export default {
    //mixins: [UnsavedChangesMixin],

    data() {
      return {
        mobileLayout: null,
        windowWidth: null,
        selectedItem: null,
        ifShowList: true,
        todaysEvent: null,
        deliveries: null,
        loadingDeliveries: true,
        job: null,
        mdiArrowExpandAll,
        mdiArrowCollapseAll,
      };
    },

    computed: {
      isDelivering() {
        return Boolean(store.state.delivery.deliveryStatus);
      },
      uncompletedDelivery() {
        const activeDeliveryStatuses = [
          deliveryStatuses.DRIVER_COLLECTING_ORDERS,
          deliveryStatuses.IN_TRANSIT,
          deliveryStatuses.AT_DESTINATION,
        ];

        return this.deliveries.find((delivery) =>
          activeDeliveryStatuses.includes(delivery.status)
        );
      },

      ...mapGetters({
        hasNetworkError: "networkStatus/hasNetworkError",
        getTodaysEvent: "today/event",
        getDeliveries: "deliveries/get",
      }),

      mapFullMode() {
        return this.isDelivering || !this.ifShowList;
      },
    },

    watch: {
      getDeliveries: async function () {
        this.deliveries = this.getDeliveries;
        this.loadingDeliveries = false;
        store.dispatch("coordinates/setDestinations", this.deliveries);
      },
    },

    components: {
      DeliveryListItem,
      MapBox,
      TopBar,
    },

    methods: {
      checkScreen() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth > 640) {
          this.mobileLayout = false;
        } else {
          this.mobileLayout = true;
        }
      },

      resetDeliveryStatus() {
        store.dispatch("delivery/resetStatus");
      },

      containsValidOrders(delivery) {
        for (const order of delivery.orders) {
          if (order.status !== "Cancelled") {
            return true;
          }
        }
        return false;
      },

      toggleMapSize() {
        this.ifShowList = !this.ifShowList;
      },
    },

    created() {
      window.addEventListener("resize", this.checkScreen);
      this.checkScreen();
      // this.setUnsavedChanges(true);
    },

    async mounted() {
      this.todaysEvent = await this.getTodaysEvent;

      if (this.todaysEvent == null) {
        store.dispatch("apiPrivate/logout");
      }

      store.dispatch("deliveries/startTask");
      store.dispatch("coordinates/setVenue", this.todaysEvent.venueCenter);
    },

    unmounted() {
      store.dispatch("deliveries/stopTask");
    },
  };
</script>

<style lang="scss">
  .app-container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .main-content-container {
    display: flex;
    flex-grow: 1;
    position: relative;
    width: 100%;
    min-height: 100%;
    height: 100%;
    box-sizing: border-box;
    background: $col_offwhite;
    overflow: hidden;
    flex-direction: column;
    top: 4em;
  }

  button.map-size-toggle {
    background: #fff;
    z-index: 9;
    top: 10px;
    position: absolute;
    color: #000;
    box-shadow: 0 0 5px #b4b4b4;
    right: 10px;
  }
</style>
